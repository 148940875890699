import { gql } from '@apollo/client';

export const SeoMetaTagsFragment = gql`
  fragment SeoMetaTagsFragment on Tag {
    attributes
    content
    tag
  }
`;

export const DescriptionFragment = gql`
  fragment DescriptionFragment on FaqTopicModelDescriptionField {
    blocks
    links
    value
  }
`;

export const ColorFragment = gql`
  fragment ColorFragment on ColorField {
    red
    green
    blue
    alpha
    hex
    cssRgb
  }
`;

// See: https://www.datocms.com/blog/offer-responsive-progressive-lqip-images-in-2020
export const ResponsiveImageFragment = gql`
  fragment ResponsiveImage on ResponsiveImage {
    alt
    aspectRatio
    base64
    bgColor
    height
    sizes
    src
    srcSet
    title
    webpSrcSet
    width
  }
`;

export const ButtonFragment = gql`
  fragment ButtonFragment on ButtonRecord {
    id
    label
    url
    buttonType
    openInNewTab
  }
`;

export const ImageBlockRecordFragment = gql`
  ${ResponsiveImageFragment}
  ${ColorFragment}
  fragment ImageBlockRecordFragment on ImageBlockRecord {
    id
    image {
      __typename
      thumbhash
      alt
      colors {
        __typename
        ...ColorFragment
      }
      filename
      focalPoint {
        __typename
        x
        y
      }
      height
      id
      responsiveImage(imgixParams: { fit: fill, auto: [compress, format], crop: faces }) {
        __typename
        ...ResponsiveImage
      }
      thumbhash
      title
      url
      width
    }
  }
`;

export const DutyPackageFragment = gql`
  ${ColorFragment}
  fragment DutyPackageFragment on DutyPackageRecord {
    id
    color {
      ...ColorFragment
    }
    buttonText
    description
    id
    key
    position
    title
  }
`;

export const StartIntakeBlockFragment = gql`
  ${ColorFragment}
  ${DutyPackageFragment}
  fragment StartIntakeBlockFragment on StartIntakeRecord {
    __typename
    id
    title
    formId
    dutyPackage {
      ...DutyPackageFragment
    }
    voucherCode {
      id
      code
    }
    buttonText
    titleColor {
      ...ColorFragment
    }
    bgColor {
      ...ColorFragment
    }
    buttonColor {
      ...ColorFragment
    }
  }
`;

export const StructuredTextBlockFragment = gql`
  ${ButtonFragment}
  ${ImageBlockRecordFragment}
  ${StartIntakeBlockFragment}
  fragment StructuredTextBlockFragment on StructuredTextBlockRecord {
    structuredText {
      value
      links
      blocks {
        ... on ImageBlockRecord {
          ...ImageBlockRecordFragment
        }
        ... on ButtonRecord {
          ...ButtonFragment
        }
        ... on StartIntakeRecord {
          ...StartIntakeBlockFragment
        }
      }
    }
  }
`;

export const BasicImageFragment = gql`
  ${ResponsiveImageFragment}
  fragment BasicImageFragment on FileField {
    id
    url
    focalPoint {
      x
      y
    }
    responsiveImage {
      ...ResponsiveImage
    }
  }
`;

export const BlogPostQuickLinkFragment = gql`
  ${BasicImageFragment}
  ${ResponsiveImageFragment}
  fragment BlogPostQuickLinkFragment on BlogPostQuickLinkRecord {
    id
    title
    blogPosts {
      id
      title
      slug
      description
      image {
        id
        alt
        responsiveImage(
          imgixParams: { w: 400, h: 400, fit: crop, crop: faces, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
      }
    }
  }
`;

export const MiscQuickLinkFragment = gql`
  ${ResponsiveImageFragment}
  fragment MiscQuickLinkFragment on MiscQuickLinkRecord {
    id
    title
    items {
      id
      heading
      url
      text
      image {
        id
        responsiveImage(
          imgixParams: { w: 400, h: 400, fit: crop, crop: faces, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
      }
    }
  }
`;

export const ProductTypeFragment = gql`
  ${ResponsiveImageFragment}
  fragment ProductTypeFragment on ProductTypeRecord {
    id
    title
    advantages {
      blocks
      links
      value
    }
    disadvantages {
      blocks
      links
      value
    }
    image {
      id
      alt
      responsiveImage(
        imgixParams: { w: 400, h: 400, fit: crop, crop: faces, auto: [compress, format] }
      ) {
        ...ResponsiveImage
      }
    }
  }
`;

export const LongReadQuickLinkFragment = gql`
  ${ResponsiveImageFragment}
  fragment LongReadQuickLinkFragment on LongReadQuickLinkRecord {
    id
    title
    longReads {
      id
      slug
      title
      description
      parentPage: product {
        ... on EnergySavingPageRecord {
          id
          name
          parentPage: parentSolution {
            id
            slug
          }
        }
        ... on ProductPageRecord {
          id
          slug
          parentPage: parentSolution {
            id
            slug
          }
        }
        ... on FinancingPageRecord {
          id
          slug
          parentPage: parentSolution {
            id
            slug
          }
        }
      }
      image {
        id
        responsiveImage(
          imgixParams: { w: 400, h: 400, fit: crop, crop: faces, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
      }
    }
  }
`;

export const StepperFragment = gql`
  fragment StepperFragment on StepperRecord {
    id
    title
    steps {
      id
      text
      title
    }
  }
`;

export const PointCardsBlockFragment = gql`
  fragment PointCardsBlockFragment on PointCardsBlockRecord {
    id
    pointCards {
      title
      text
      urlText
      url
      id
      icon
    }
  }
`;

export const CTASectionBlockFragment = gql`
  ${BasicImageFragment}
  ${ButtonFragment}
  ${StructuredTextBlockFragment}
  ${ColorFragment}
  fragment CTASectionBlockFragment on CtaSectionBlockRecord {
    id
    bgColor {
      ...ColorFragment
    }
    hideCallout
    ctaSection {
      id
      newText {
        ...StructuredTextBlockFragment
      }
      mobileText
      title
      priceTitle
      price
      period
      image {
        id
        alt
        focalPoint {
          x
          y
        }
        responsiveImage(
          imgixParams: { w: 800, h: 800, fit: crop, crop: faces, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
        mobileResponsiveImage: responsiveImage(
          imgixParams: { w: 760, h: 200, fit: crop, crop: faces, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
      }
      button {
        ...ButtonFragment
      }
    }
  }
`;

export const CTACardBlockFragment = gql`
  ${BasicImageFragment}
  ${ColorFragment}
  ${ButtonFragment}
  fragment CTACardBlockFragment on CtaCardBlockRecord {
    id
    bgColor {
      ...ColorFragment
    }
    ctaCard {
      button {
        ...ButtonFragment
      }
      description
      id
      title
      image {
        ...BasicImageFragment
      }
      bgColor {
        ...ColorFragment
      }
    }
  }
`;

export const AboutUsMetricsFragment = gql`
  ${ColorFragment}
  fragment AboutUsMetricsFragment on AboutUsMetricsBlockRecord {
    id
    color {
      ...ColorFragment
    }
    showMoney
    showScans
    showReviewScore
    showElectricity
    showEmission
  }
`;

export const PrimaryImageFragment = gql`
  ${ResponsiveImageFragment}
  ${ButtonFragment}
  fragment PrimaryImageFragment on PrimaryImageRecord {
    image {
      id
      responsiveImage(imgixParams: { fit: crop, crop: faces, auto: [compress, format] }) {
        ...ResponsiveImage
      }
    }
    id
    text
    textColor
    primaryImageCard {
      text
      id
      button {
        ...ButtonFragment
      }
    }
  }
`;

export const TestimonialBlockFragment = gql`
  ${ButtonFragment}
  fragment TestimonialBlockFragment on TestimonialBlockRecord {
    id
    testimonials {
      image {
        url
        id
        responsiveImage(
          imgixParams: { fit: crop, crop: faces, w: 360, h: 550, auto: [compress, format] }
        ) {
          ...ResponsiveImage
        }
      }
      city
      date
      id
      kwhSaved
      moneySaved
      name
      quote
      rating
      testimonialSolutions {
        id
        name
      }
      button {
        ...ButtonFragment
      }
    }
  }
`;

export const ProductHeroFragment = gql`
  ${ResponsiveImageFragment}
  ${ImageBlockRecordFragment}
  ${ButtonFragment}
  ${StructuredTextBlockFragment}
  fragment ProductHeroFragment on ProductHeroRecord {
    id
    text: newText {
      ...StructuredTextBlockFragment
    }
    title
    image {
      id
      responsiveImage(
        imgixParams: { w: 800, h: 800, fit: crop, crop: faces, auto: [compress, format] }
      ) {
        __typename
        ...ResponsiveImage
      }
      mobileResponsiveImage: responsiveImage(
        imgixParams: { w: 760, h: 200, fit: crop, crop: faces, auto: [compress, format] }
      ) {
        __typename
        ...ResponsiveImage
      }
    }
    buttons {
      ...ButtonFragment
    }
  }
`;

export const MetaTagFragment = gql`
  fragment MetaTagFragment on Tag {
    attributes
    content
    tag
  }
`;

export const AnchorLinkFragment = gql`
  fragment AnchorLinkFragment on AnchorLinkRecord {
    id
    anchors {
      id
      label
      anchor
    }
  }
`;

export const CategoryFieldFragment = gql`
  ${SeoMetaTagsFragment}
  fragment CategoryFieldFragment on FaqCategoryRecord {
    id
    slug
    title
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
    parentCategory {
      id
      title
      slug
    }
  }
`;

export const CategoryFragment = gql`
  ${ResponsiveImageFragment}
  ${SeoMetaTagsFragment}
  fragment CategoryFragment on FaqCategoryRecord {
    title
    slug
    subHeading
    id
    isParent
    isPrimary
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
    image {
      url
      responsiveImage(
        imgixParams: { fit: crop, crop: faces, w: 150, h: 150, auto: [compress, format] }
      ) {
        ...ResponsiveImage
      }
    }
    parentCategory {
      slug
      id
    }
  }
`;

export const TopicFieldsFragment = gql`
  ${CategoryFieldFragment}
  ${DescriptionFragment}
  ${ResponsiveImageFragment}
  ${SeoMetaTagsFragment}
  fragment TopicFieldsFragment on FaqTopicRecord {
    _publishedAt
    id
    slug
    title
    subHeading
    isHighlighted
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
    description {
      ...DescriptionFragment
    }
    relatedArticles {
      id
      title
      slug
      description {
        ...DescriptionFragment
      }
      category {
        id
        slug
      }
      image {
        id
        responsiveImage(
          imgixParams: { fit: crop, crop: faces, auto: [compress, format], w: 350, h: 150 }
        ) {
          ...ResponsiveImage
        }
      }
    }
    image {
      id
      url
      responsiveImage(imgixParams: { fit: fillmax, auto: [compress, format], w: 800, h: 600 }) {
        ...ResponsiveImage
      }
    }
    category {
      ...CategoryFieldFragment
    }
  }
`;

export const BlogTagFragment = gql`
  ${SeoMetaTagsFragment}
  fragment BlogTagFragment on BlogTagRecord {
    title
    slug
    id
    isHighlighted
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
  }
`;

export const BlogPostFragment = gql`
  ${ImageBlockRecordFragment}
  ${ButtonFragment}
  ${CTACardBlockFragment}
  ${BlogTagFragment}
  ${ResponsiveImageFragment}
  ${SeoMetaTagsFragment}
  fragment BlogPostFragment on BlogPostRecord {
    _publishedAt
    _updatedAt
    firstPublishedAt
    id
    title
    slug
    description
    spotlightPosition
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
    category {
      name
      id
      slug
    }
    author {
      id
      name
      bio
      picture {
        url
      }
      slug
    }
    image {
      id
      url
      responsiveImage(imgixParams: { fit: fillmax, auto: [compress, format], w: 800, h: 600 }) {
        ...ResponsiveImage
      }
    }
    tags {
      ...BlogTagFragment
    }
    structuredText {
      links
      value
      blocks {
        ... on ImageBlockRecord {
          ...ImageBlockRecordFragment
        }
        ... on ButtonRecord {
          ...ButtonFragment
        }
        ... on CtaCardBlockRecord {
          ...CTACardBlockFragment
        }
      }
    }
  }
`;

export const OmniformBlockFragment = gql`
  fragment OmniformBlockFragment on OmniformRecord {
    formKey
    formStyle
    submitText
    textVariables
    orderNeeded
  }
`;

export const CampaignContainerFragment = gql`
  ${DutyPackageFragment}
  ${ResponsiveImageFragment}
  ${StructuredTextBlockFragment}
  ${OmniformBlockFragment}
  ${StartIntakeBlockFragment}
  fragment CampaignContainerFragment on CampaignContainerModelCampaignContainerContentField {
    __typename
    ... on BespaarcheckRecord {
      __typename
      id
      showBespaarcheck
      redirectUrl
    }
    ... on ComponentCustomerFeedbackRecord {
      __typename
      id
      customers {
        id
        name
        product
        feedback
        image {
          id
          responsiveImage(
            imgixParams: { w: 50, h: 50, fit: crop, crop: faces, auto: [compress, format] }
          ) {
            ...ResponsiveImage
          }
        }
      }
    }
    ... on ComponentDutyPackageRecord {
      id
      componentDutyPackage {
        ...DutyPackageFragment
      }
      description
    }
    ... on ComponentRequestFormRecord {
      __typename
      description
      voucherCode {
        id
        code
      }
      dutyPackage {
        ...DutyPackageFragment
      }
      id
    }
    ... on ComponentRequestFormStandaloneRecord {
      id
      description
      voucherCode {
        id
        code
      }
      dutyPackage {
        ...DutyPackageFragment
      }
    }
    ... on QuotesOrPlanRecord {
      id
      qopDutyPackage {
        id
        key
      }
      qopPlanContent {
        value
        links
        blocks
      }
      qopPlanTitle
      qopPlanImage {
        id
        url
        responsiveImage(imgixParams: { fit: fill, auto: [compress, format], crop: faces, w: 640 }) {
          ...ResponsiveImage
        }
      }
      qopQuotesContent {
        blocks
        value
        links
      }
      qopQuotesTitle
      qopQuotesImage {
        id
        url
        responsiveImage(imgixParams: { fit: fill, auto: [compress, format], crop: faces, w: 640 }) {
          ...ResponsiveImage
        }
      }
    }
    ... on ImageBlockRecord {
      __typename
      id
      image {
        url
        id
        responsiveImage(imgixParams: { fit: fill, auto: [compress, format], crop: faces, w: 640 }) {
          ...ResponsiveImage
        }
      }
    }
    ... on StructuredTextBlockRecord {
      __typename
      id
      ...StructuredTextBlockFragment
    }
    ... on OmniformRecord {
      __typename
      id
      ...OmniformBlockFragment
    }
    ... on StartIntakeRecord {
      __typename
      id
      ...StartIntakeBlockFragment
    }
  }
`;

export const FaqItemFragment = gql`
  ${ImageBlockRecordFragment}
  fragment FaqItemFragment on FaqItemRecord {
    id
    question
    answer {
      value
      links
      blocks {
        __typename
        ... on ImageBlockRecord {
          ...ImageBlockRecordFragment
        }
      }
    }
    topic {
      id
      category {
        id
      }
    }
  }
`;

export const FaqDropdownFragment = gql`
  ${ButtonFragment}
  ${ImageBlockRecordFragment}
  ${FaqItemFragment}
  fragment FaqDropdownFragment on FaqDropdownRecord {
    title
    id
    button {
      ...ButtonFragment
    }
    items {
      ...FaqItemFragment
    }
  }
`;

export const PageContentFragment = gql`
  ${ResponsiveImageFragment}
  ${BlogPostQuickLinkFragment}
  ${LongReadQuickLinkFragment}
  ${MiscQuickLinkFragment}
  ${StepperFragment}
  ${PointCardsBlockFragment}
  ${CTASectionBlockFragment}
  ${CTACardBlockFragment}
  ${BasicImageFragment}
  ${PrimaryImageFragment}
  ${StructuredTextBlockFragment}
  ${FaqDropdownFragment}
  ${TestimonialBlockFragment}
  ${AnchorLinkFragment}
  ${ResponsiveImageFragment}
  ${AboutUsMetricsFragment}
  fragment PageContentFragment on ProductPageContentModelContentField {
    ... on AboutUsMetricsBlockRecord {
      ...AboutUsMetricsFragment
    }
    ... on AnchorLinkRecord {
      ...AnchorLinkFragment
    }
    ... on BlogPostQuickLinkRecord {
      ...BlogPostQuickLinkFragment
    }
    ... on LongReadQuickLinkRecord {
      ...LongReadQuickLinkFragment
    }
    ... on MiscQuickLinkRecord {
      ...MiscQuickLinkFragment
    }
    ... on StepperRecord {
      ...StepperFragment
    }
    ... on PointCardsBlockRecord {
      ...PointCardsBlockFragment
    }
    ... on CtaSectionBlockRecord {
      ...CTASectionBlockFragment
    }
    ... on CtaCardBlockRecord {
      ...CTACardBlockFragment
    }
    ... on HeadingRecord {
      id
      text
    }
    ... on ImageBlockRecord {
      id
      image {
        id
        responsiveImage(imgixParams: { fit: clip, w: 640, auto: [compress, format] }) {
          ...ResponsiveImage
        }
      }
    }
    ... on PrimaryImageRecord {
      ...PrimaryImageFragment
    }
    ... on StructuredTextBlockRecord {
      ...StructuredTextBlockFragment
    }
    ... on FaqDropdownRecord {
      ...FaqDropdownFragment
    }
    ... on TestimonialBlockRecord {
      ...TestimonialBlockFragment
    }
  }
`;

export const AboutUsPageFragment = gql`
  ${SeoMetaTagsFragment}
  ${ResponsiveImageFragment}
  ${StructuredTextBlockFragment}
  ${ResponsiveImageFragment}
  ${AboutUsMetricsFragment}
  fragment AboutUsFragment on AboutUsModelRecord {
    __typename
    id
    title
    slug
    description
    image {
      id
      url
      responsiveImage(
        imgixParams: { w: 400, h: 400, fit: crop, crop: faces, auto: [compress, format] }
      ) {
        ...ResponsiveImage
      }
    }
    seo: _seoMetaTags {
      ...SeoMetaTagsFragment
    }
    content {
      __typename
      ... on CampaignContainerRecord {
        bgColor {
          ...ColorFragment
        }
        campaignContainerContent {
          ... on AboutUsMetricsBlockRecord {
            id
            ...AboutUsMetricsFragment
          }
          ... on StructuredTextBlockRecord {
            id
            ...StructuredTextBlockFragment
          }
          ... on ImageBlockRecord {
            id
            image {
              id
              responsiveImage(imgixParams: { fit: clip, w: 640, auto: [compress, format] }) {
                ...ResponsiveImage
              }
            }
          }
        }
      }
      ... on ProductPageContentRecord {
        id
        anchor
        content {
          ... on StructuredTextBlockRecord {
            id
            ...StructuredTextBlockFragment
          }
          ... on ImageBlockRecord {
            id
            image {
              id
              responsiveImage(imgixParams: { fit: clip, w: 640, auto: [compress, format] }) {
                ...ResponsiveImage
              }
            }
          }
        }
      }
    }
  }
`;
